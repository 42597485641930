import React, {useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Popconfirm, Row, Table, Typography, Modal, notification} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import ChildModal from "./ChildModal";
import axios from "axios";
import moment from 'moment'


export default function ChildTable({childrens_pros, user_id, onChangeChild}) {
    const [form] = Form.useForm();

    const handleDelete = (value) => {
        axios.delete(
            `${process.env.REACT_APP_API_ENDPOINT}/chidlrens/${value.id}`,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                notification['success']({
                    message: "Ребенок был успешно удален"
                });
            })
            .catch(function (error) {
                notification['error']({
                    message: "Ошибка при удалении ребенка"
                });
            })
            .then(function () {
                onChangeChild()
            });
    };

    const handleChangeChild = () => {
        onChangeChild()
    }


    const columns = [
        {
            title: 'Имя',
            dataIndex: 'firstname',
            editable: true,

        },
        {
            title: 'Дата рождения',
            dataIndex: 'birth_date',
            editable: true,
            render: (_, record) => {
                return <div>{ record.birth_date ? moment(record.birth_date).format("YYYY-MM-DD") : 'Не указана'}</div>
            }
        },
        {
            title: 'Действия',
            dataIndex: 'operation',
            render: (_, record) => {
                return <div>
                    <ChildModal user_id={user_id} child={record} onChangeChild={handleChangeChild}/>

                    <Popconfirm title="Подтверждаете удаление?" onConfirm={() => handleDelete(record)}>
                        <a>Удалить</a>
                    </Popconfirm>
                </div>
            },
        },
    ];

    return (
        <div>
            <Form form={form} component={false}>
                <Table
                    size="small"
                    bordered
                    dataSource={childrens_pros}
                    columns={columns}
                    title={() => <div>
                        <Row gutter={[24, 32]}>
                            <Col span={6}>Дети пользователей</Col>
                            <Col span={6} />
                            <Col span={6} />
                            <Col span={6} align="end">
                                <ChildModal user_id={user_id} onChangeChild={handleChangeChild}/>
                            </Col>
                        </Row>
                    </div>}
                />
            </Form>
        </div>
    );
};