import axios from "axios";
import React, {useState, useEffect} from "react";
import {Col, Popconfirm, Row, Table} from "antd";
import EmployeeScheduleModal from "./EmployeeScheduleModal";
import moment from 'moment'


export function EmployeeSchedule({employee_id}) {
    const [loading, setLoading] = useState();
    const [schedule, setSchedule] = useState();


    function getEmployeeSchedule() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employees/${employee_id}/schedule`, {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                setSchedule(response.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // setInProgress(false);
            });
    }

    const handleDelete = (value) => {
        console.log(value)

        return axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/employees/${employee_id}/schedule/${value.id}`, {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                getEmployeeSchedule()
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
            });
    }

    const handleChangeSchedule = () => {
        getEmployeeSchedule()
    }

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'day',
            defaultSortOrder: 'descend',
            sorter: (a, b) => moment(a.day).diff(b.day) //a.day.length - b.day.length,
            // sortDirections: ['descend'],
        },
        {
            title: 'Начало',
            dataIndex: 'start',
        },
        {
            title: 'Окончание',
            dataIndex: 'end',
        },
        {
            title: 'Действия',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Popconfirm title="Подтверждаете удаление?" onConfirm={() => handleDelete(record)}>
                    <a>Удалить</a>
                </Popconfirm>
            },
        },
    ];

    useEffect(() => {
        getEmployeeSchedule()
    }, [])


    return <div>

        <Table
            size="small"
            bordered
            dataSource={schedule}
            columns={columns}
            title={() => <div>
                <Row gutter={[24, 32]}>
                    <Col span={6}>Расписание сотрудника</Col>
                    <Col span={6} />
                    <Col span={6} />
                    <Col span={6} align="end">
                        <EmployeeScheduleModal employee_id={employee_id} onChangeSchedule={handleChangeSchedule} />
                    </Col>
                </Row>
            </div>}
        />

    </div>
}