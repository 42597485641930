import MainLayout from "../MainLayout";
import React, {useEffect, useState} from 'react';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import axios from "axios";
import moment from 'moment'
import {Link, useHistory} from "react-router-dom";
import 'moment/locale/ru';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import './Appointments.less'
import {
    Button,
    Col,
    Row,
    notification,
    Modal,
    Descriptions,
    Popconfirm,
    Select,
    Form,
    DatePicker,
    Input
} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";


moment.locale('ru')
const bc_localizer = momentLocalizer(moment)

const DragAndDropCalendar = withDragAndDrop(Calendar);
const {Option} = Select;
const {TextArea} = Input;

export function Appointments() {
    const localizer = momentLocalizer(moment)
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(localStorage.getItem('calendarDate') || moment().format("YYYY-MM-DD"));
    const [loading, setLoading] = useState(false);
    const [loadingEditAppointment, setLoadingEditAppointment] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [waitingRequests, setWaitingRequests] = useState([]);  // Лист ожидания
    const [isModalWaitingRequestVisible, setIsModalWaitingRequestVisible] = useState(false);  // Модальное окно листа ожидания
    const [availableTimeslots, setAvailableTimeslots] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [workingEmployee, setWorkingEmployee] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersOptions, setUsersOptions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const [calendarView, setCalendarView] = useState(localStorage.getItem('calendarView') || 'day');
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [form] = Form.useForm();

    const findWorkingEmployee = (date, employees) => {
        if (calendarView === 'week') {
            let startDate = moment(selectedDate).clone().startOf('week');
            let endDate = moment(selectedDate).clone().endOf('week');
            let dates = []

            while (startDate.isSameOrBefore(endDate)) {
                dates.push(startDate.format('YYYY-MM-DD'));
                startDate.add(1, 'days');
            }

            setWorkingEmployee(employees.filter(employee => employee.schedule.filter(sch => dates.includes(sch.day)).length > 0))
        } else if (calendarView === 'day') {
            setWorkingEmployee(employees.filter(employee => employee.schedule.filter(sch => sch.day === date).length > 0))
        }
    }


    function getEmployees() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employees`)
            .then(function (response) {
                let employees = response.data
                setEmployees(employees)

                //Свободные слоты для записи
                let timeslots_for_appointment = []

                employees.forEach((employee, index) => {
                    employee['fullname'] = `${employee['firstname']} ${employee['lastname'] == null ? "" : employee['lastname']}`

                    employee['schedule'].forEach((schedule_day, index) => {

                        schedule_day['timeslots'].forEach((slot) => {
                            timeslots_for_appointment.push({
                                id: `${schedule_day.id}_${slot}`,
                                title: "Свободный слот",
                                start: moment(`${schedule_day.day} ${slot}`).toDate(),
                                end: moment(`${schedule_day.day} ${slot}`).add(30, "minute").toDate(),
                                employee: {
                                    id: employee.id,
                                    fullname: employee['fullname']
                                },
                                type: "available_slot"
                            })
                        })
                    })

                    return employee
                });

                setAvailableTimeslots(timeslots_for_appointment)

                findWorkingEmployee(selectedDate, employees)
                // setWorkingEmployee(employees.filter(employee => employee.schedule.filter(sch => sch.day == selectedDate).length > 0))
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    /**
     * Получение листа ожидания.
     * @returns {Promise<AxiosResponse<any>>}
     */
    function getWaitingRequests() {
        let startDate;
        let endDate;

        if (calendarView === 'week') {
            startDate = moment(selectedDate).clone().startOf('week');
            endDate = moment(selectedDate).clone().endOf('week');
        } else {
            startDate = moment(selectedDate).clone();
            endDate = moment(selectedDate).clone().add(1, 'day');
        }

        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/waiting_requests`,
            {
                headers: {'api': localStorage.getItem('token')},
                params: {
                    'start': startDate.format("YYYY-MM-DD 00:00:00"),
                    'end': endDate.format("YYYY-MM-DD 00:00:00")
                }
            }
        )
            .then(function (response) {
                let result = response.data;

                let _waiting_requests = []
                result.forEach(val => {
                    _waiting_requests.push({
                        id: val.id,
                        start: new Date(val.start),
                        end: new Date(val.start),
                        comment: val.comment,
                        user: val.user,
                        type: "waiting_request"
                    })
                })
                setWaitingRequests(_waiting_requests);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function getAppointments() {
        let startDate;
        let endDate;

        if (calendarView === 'week') {
            startDate = moment(selectedDate).clone().startOf('week');
            endDate = moment(selectedDate).clone().endOf('week');
        } else {
            startDate = moment(selectedDate).clone();
            endDate = moment(selectedDate).clone();
        }

        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/appointments`,
            {
                headers: {'api': localStorage.getItem('token')},
                params: {
                    'start': startDate.format("YYYY-MM-DD 00:00:00"),
                    'end': endDate.format("YYYY-MM-DD 23:59:59")
                }
            }
        )
            .then(function (response) {
                let result = response.data;

                let prepared_appointments = []
                result.forEach(val => {
                    prepared_appointments.push({
                        id: val.id,
                        title: val.title,
                        start: new Date(val.start),
                        end: new Date(val.end),
                        user_id: val.user_id,
                        child_id: val.child_id,
                        status: val.status,
                        user: val.user,
                        employee: val.employee,
                        resourceId: val.employee_id,
                        type: val.type ? val.type : "appointment"
                    })
                })
                setAppointments(prepared_appointments);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function getUsers() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users`, {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                let local_user_list = []

                response.data.forEach((item, index) => {
                    let fullname = `${item['firstname']} ${item['lastname'] == null ? "" : item['lastname']} (${item['phone']})`
                    item['fullname'] = fullname
                    local_user_list.push({'label': fullname, 'value': item})
                    setUsersOptions(local_user_list)
                });

                setUsers(response.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    useEffect(() => {
        Promise.all([getAppointments(), getEmployees(), getUsers(), getWaitingRequests()])
            .then(function (results) {
                setLoading(false);
            });

        handleChangeCalendarDate(selectedDate)


    }, [])

    useEffect(() => {
        getAppointments();
        getWaitingRequests();

    }, [calendarView, selectedDate])

    const formats = {
        eventTimeRangeFormat: () => {
            return "";
        },
    };

    /**
     * Обработчик нажатия на запись
     * @param event Детали записи
     */
    function handleSelectEvent(event) {
        // setSelectedEvent(event)
        // setShowModalViewEvent(true)

        if (event.type === 'waiting_request') {
            setSelectedEvent(event)
            setIsModalWaitingRequestVisible(true);

        } else if (event.type === 'appointment' || event.type === 'repeated') {
            setIsModalVisible(true)
            setSelectedEvent(event)
        } else if (event.type === 'available_slot') {

            history.push({
                pathname: "/appointments/new",
                state: {
                    employee_id: event.employee.id,
                    date: moment(event.start).format("YYYY-MM-DD"),
                    timeslot: moment(event.start).format("HH:mm:00")
                }
            })
        }
    }

    function handleCreateEvent(event) {
        console.log(event)
        // console.log(event.start, event.end, event.resourceId)
        // setSelectedStartSlot(event.start)
        // setSelectedEndSlot(event.end)
        // setSelectedResourceId(event.resourceId)
        // setShow(true)


        history.push({
            pathname: "/appointments/new",
            state: {
                employee_id: event.resourceId,
                date: moment(event.start).format("YYYY-MM-DD"),
                timeslot: moment(event.start).format("HH:mm:00")
            }
        })
    }

    const handleChangeCalendarDate = (date) => {
        console.log(date)

        findWorkingEmployee(moment(date).format("YYYY-MM-DD"), employees)
        // setWorkingEmployee(employees.filter(employee => employee.schedule.filter(sch => sch.day === moment(date).format("YYYY-MM-DD")).length > 0))
        setSelectedDate(moment(date).format("YYYY-MM-DD"))
        localStorage.setItem('calendarDate', moment(date).format("YYYY-MM-DD"))
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedEmployee();
        setSelectedEvent();
    };

    const handleCancelWaitingRequest = () => {
        setIsModalWaitingRequestVisible(false);
        setSelectedEvent();
    };
    
    const handleDeleteWaitingRequest = () => {
        axios.delete(
            `${process.env.REACT_APP_API_ENDPOINT}/api/waiting_requests/${selectedEvent.id}`,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                setIsModalWaitingRequestVisible(false);
                notification['success']({
                    message: "Запись успешно удалена"
                });
                getWaitingRequests();
            })
            .catch(function (error) {
                notification['error']({
                    message: "Ошибка при удалении записи"
                });
            })
    }

    const handleCancelAppointment = () => {
        axios.delete(
            `${process.env.REACT_APP_API_ENDPOINT}/appointments/${selectedEvent.id}`,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                setIsModalVisible(false);
                notification['success']({
                    message: "Запись успешно удалена"
                });
                getAppointments();
                getEmployees();
            })
            .catch(function (error) {
                notification['error']({
                    message: "Ошибка при удалении записи"
                });
            })
            .then(function () {

            })
    };

    /**
     * Обработчик изменения вида календаря
     * @param view
     */
    const handleChangeView = (view) => {
        localStorage.setItem('calendarView', view)
        setCalendarView(view)
        findWorkingEmployee(selectedDate, employees)
    }

    const CustomEvent = (event) => {
        return (
            <span> <strong> event.title </strong> </span>
        )
    }

    function Event(prop) {
        if (prop.event.type === 'waiting_request') {
            return <div className="waiting_request">
                <div className="">
                    {prop.event.user.firstname} {prop.event.user.lastname}
                </div>
            </div>
        } else if (prop.event.type === 'appointment') {
            return <div className="appointment">
                <div className="">
                    {prop.event.user.firstname} {prop.event.user.lastname}
                    {(prop.event.user.childrens.filter((child) => prop.event.child_id === child.id).length > 0 ? ` (${prop.event.user.childrens.filter((child) => prop.event.child_id === child.id)[0].firstname})` : "")}
                </div>
                <div className="">
                    {prop.event.employee ? `${prop.event.employee.firstname} ${prop.event.employee.lastname}` : ''}
                </div>
            </div>
        } else if (prop.event.type === 'repeated') {
            return <div className="repeated">
                <div className="">
                    {prop.event.user.firstname} {prop.event.user.lastname}
                    {(prop.event.user.childrens.filter((child) => prop.event.child_id === child.id).length > 0 ? ` (${prop.event.user.childrens.filter((child) => prop.event.child_id === child.id)[0].firstname})` : "")}
                </div>
                <div className="">
                    {prop.event.employee ? `${prop.event.employee.firstname} ${prop.event.employee.lastname}` : ''}
                </div>
            </div>
        } else if (prop.event.type === 'available_slot') {
            return <div className="available-slot">
                {prop.event.employee.fullname}
            </div>
        }
    }

    //
    // function timeSlotWrapper(prop) {
    //     console.log(prop)
    // }

    /**
     * Изменение сотрудника в записи
     */
    function handleChangeEmployeeInAppointment(employee) {
        setSelectedEmployee(employee)
    }

    function handleChangeAppointment() {
        setLoadingEditAppointment(true);

        axios.put(
            `${process.env.REACT_APP_API_ENDPOINT}/appointments/${selectedEvent.id}`,
            {employee_id: selectedEmployee},
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                notification['success']({
                    message: "Запись успешно изменена"
                });
                getAppointments();
                // getEmployees();
            })
            .catch(function (error) {
                notification['error']({
                    message: "Ошибка при изменении записи"
                });
            })
            .then(function () {
                setLoadingEditAppointment(false);
            })

    }

    function handleSubmitWaitingRequestForm(values) {

        let appointment = {
            start: `${moment(values.date).format('YYYY-MM-DD')} 00:00:00`,
            comment: values.comment,
            user_id: values.user_id
        }
        axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/waiting_requests`,
            appointment,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                notification['success']({
                    message: "Успешно добавлено в лист ожидания"
                });

                getWaitingRequests();
                setIsModalWaitingRequestVisible(false);
            })
            .catch(function (error) {
                notification['error']({
                    message: "Ошибка при создании записи"
                });
            })
            .then(function () {

            })
    }

    return <MainLayout>
        <div>
            <Modal
                title="Детали записи"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button type="text" key="back" onClick={handleCancel}>
                        Закрыть
                    </Button>,
                    <Popconfirm title="Подтверждаете отмену записи?" onConfirm={() => handleCancelAppointment()}>
                        <Button danger type="text">
                            Отменить запись
                        </Button>
                    </Popconfirm>,
                    <Button key="save" type="primary" disabled={!selectedEmployee} loading={loadingEditAppointment}
                            onClick={handleChangeAppointment}>
                        Сохранить
                    </Button>,
                ]}

            >
                {selectedEvent && selectedEvent.user && selectedEvent.employee && selectedEvent.start ?
                    <Descriptions bordered title="" layout="horizontal" column={1} size={'small'}>
                        <Descriptions.Item label="Пользователь">
                            <Link to={`/users/${selectedEvent && selectedEvent.user.id}`}>
                                {selectedEvent && selectedEvent.user.firstname} {selectedEvent && selectedEvent.user.lastname}
                            </Link>
                        </Descriptions.Item>
                        <Descriptions.Item label="Ребенок пользователя">
                            {selectedEvent && (selectedEvent.user.childrens.filter((child) => selectedEvent.child_id === child.id).length > 0 ? selectedEvent.user.childrens.filter((child) => selectedEvent.child_id === child.id)[0].firstname : "Не указан")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Сотрудник">
                            {/*{selectedEvent && selectedEvent.employee.firstname} {selectedEvent && selectedEvent.employee.lastname}*/}

                            {selectedEvent && <Select
                                showSearch
                                placeholder="Выберите сотрудника"
                                optionFilterProp="fullname"
                                value={selectedEmployee || selectedEvent.employee.id}
                                onChange={handleChangeEmployeeInAppointment}
                                defaultValue={selectedEvent.employee.id}
                                filterOption={(input, option) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                }
                            >
                                {
                                    employees.map((employee) => {
                                        return <Option value={`${employee.id}`}>{employee.fullname}</Option>
                                    })
                                }

                            </Select>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="Дата и время записи">
                            {moment(selectedEvent && selectedEvent.start).format("YYYY-MM-DD HH:mm")}
                        </Descriptions.Item>
                    </Descriptions> :
                    ''
                }

            </Modal>


            <Modal
                title="Лист ожидания"
                visible={isModalWaitingRequestVisible}
                onCancel={handleCancelWaitingRequest}
                footer={false}
            >

                {selectedEvent ? <div>
                        <Descriptions bordered title="" layout="horizontal" column={1} size={'small'}>
                            <Descriptions.Item label="Пользователь">
                                <Link to={`/users/${selectedEvent && selectedEvent.user.id}`}>
                                    {selectedEvent && selectedEvent.user.firstname} {selectedEvent && selectedEvent.user.lastname}
                                </Link>
                            </Descriptions.Item>
                            <Descriptions.Item label="Дата желаемой записи">
                                {moment(selectedEvent && selectedEvent.start).format("YYYY-MM-DD HH:mm")}
                            </Descriptions.Item>
                            <Descriptions.Item label="Комментарий">
                                {selectedEvent && selectedEvent.comment}
                            </Descriptions.Item>
                        </Descriptions>

                        <Button type="text" key="back" onClick={handleCancelWaitingRequest}>
                            Закрыть
                        </Button>,
                        <Popconfirm title="Подтверждаете отмену записи?" onConfirm={() => handleDeleteWaitingRequest()}>
                            <Button danger type="text">
                                Отменить запись
                            </Button>
                        </Popconfirm>

                    </div> :

                    <Form
                        labelCol={{'span': 8}}
                        wrapperCol={{'span': 16}}
                        onFinish={handleSubmitWaitingRequestForm}
                        form={form}
                    >

                        <Form.Item name={'user_id'} label="Пользователь" rules={[{required: true}]}>
                            <Select
                                showSearch
                                // style={{ width: 200 }}
                                placeholder="Выберите пользователя"
                                optionFilterProp="fullname"
                                filterOption={(input, option) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                }
                            >
                                {
                                    users.map((user) => {
                                        return <Option value={`${user.id}`}>{user.fullname}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>


                        <Form.Item name='date' label="Дата записи" rules={[{required: true}]}>
                            <DatePicker format="YYYY-MM-DD" required={true} message="Укажите дату записи"
                                        locale={locale}/>
                        </Form.Item>

                        <Form.Item name='comment' label="Комментарий" rules={[{required: true}]}>
                            <TextArea rows={4} placeholder="Укажите желаемое время" maxLength={244}/>
                        </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                }


            </Modal>


            <Row gutter={[24, 32]}>
                <Col span={8}/>
                <Col span={7}/>
                <Col span={9} align="end">
                    <Button type="primary" onClick={() => {
                        history.push('/appointments/new')
                    }}>
                        Создать запись
                    </Button> {' '}
                    <Button onClick={() => setIsModalWaitingRequestVisible(true)}>
                        Добавить в лист ожидания
                    </Button>
                </Col>
            </Row>

            <br/>
            <DragAndDropCalendar
                events={appointments.concat(availableTimeslots).concat(waitingRequests)}
                localizer={bc_localizer}
                defaultView={Views.DAY}
                views={['day', 'week']}
                view={calendarView}
                date={moment(selectedDate).toDate()}
                // step={15}
                timeslots={1}
                min={new Date(2021, 1, 0, 8, 0, 0)}
                max={new Date(2021, 1, 0, 21, 0, 0)}
                selectable
                // resources={workingEmployee}  //workingEmployee  employees
                // resourceIdAccessor="id"
                // resourceTitleAccessor="fullname"
                onSelectEvent={handleSelectEvent}
                formats={formats}
                messages={{
                    allDay: 'Весь день',
                    previous: 'Назад',
                    next: 'Вперед',
                    today: 'Сегодня',
                    month: 'Месяц',
                    week: 'Неделя',
                    day: 'День',
                    agenda: 'Расписание',
                    date: 'Дата',
                    time: 'Время',
                    event: 'Событие',
                    showMore: total => `+ ${total} событий`
                }}

                onNavigate={(date) => handleChangeCalendarDate(date)}
                onView={(view) => handleChangeView(view)}
                // components={{
                //     event: Event,
                //     agenda: {
                //         event: EventAgenda,
                //     },
                // }}
                components={{
                    event: Event,
                    // timeSlotWrapper: timeSlotWrapper
                }}
                // onEventDrop={moveEvent}
                // resizable
                // onEventResize={resizeEvent}
                // onSelectSlot={handleCreateEvent}
                // onDragStart={console.log}
                // popup={true}
                // dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : null}
                // onDropFromOutside={onDropFromOutside}
                // handleDragStart={handleDragStart}
            />

        </div>
    </MainLayout>

}