import React, {useEffect, useState} from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import {useHistory} from "react-router-dom";

const { Header, Content, Footer } = Layout;



export default function MainLayout({children}) {
    let history = useHistory();
    const [collapsed, setCollapsed] = useState(false);

    function toggle() {
        setCollapsed(!collapsed)
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <div className="logo" />
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                    <Menu.Item onClick={() => history.push("/appointments")} key="1">Расписание</Menu.Item>
                    {/*<Menu.Item onClick={() => history.push("/")} key="2">Записи</Menu.Item>*/}
                    <Menu.Item onClick={() => history.push("/users")} key="3">Пользователи</Menu.Item>
                    <Menu.Item onClick={() => history.push("/employees")} key="4">Сотрудники</Menu.Item>
                </Menu>
            </Header>
            <Content className="site-layout" style={{  marginTop: 64 }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Администрирование</Breadcrumb.Item>
                    {/*<Breadcrumb.Item>Пользователи</Breadcrumb.Item>*/}
                </Breadcrumb>
                <div className="site-layout-background" style={{ minHeight: 380 }}>
                    {children}
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}/>
        </Layout>
    )
}