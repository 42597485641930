import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom"
import {Form, Input, Button, Row, Col, Divider, notification, Upload, Select, DatePicker, Checkbox} from 'antd';

import MainLayout from "../MainLayout";
import axios from "axios";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";


const { Option } = Select;

export function Appointment() {
    let { id } = useParams();
    const location = useLocation();
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState();

    const [appointment, setAppointment] = useState();
    const [employees, setEmployees] = useState([]);
    const [availableTimeslots, setAvailableTimeslots] = useState([]);
    const [users, setUsers] = useState([]);
    const [userChildrens, setUserChildrens] = useState([]);


    function getEmployees() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employees`)
            .then(function (response) {
                console.log(response);
                setEmployees(response.data)
                response.data.forEach((item, index) => {
                    item['fullname'] = `${item['firstname']} ${item['lastname'] == null ? "" : item['lastname']}`
                    return item
                });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }


    function getUsers() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users`)
            .then(function (response) {
                setUsers(response.data);
                response.data.forEach((item, index) => {
                    item['fullname'] = `${item['firstname']} ${item['lastname'] == null ? "" : item['lastname']} (${item['phone']})`
                });

            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }
    
    
    
    function handleSubmitForm(values) {
        values['title'] = `${form.getFieldValue('user_id') && users.filter(i => i.id === form.getFieldValue('user_id'))[0].firstname} ${form.getFieldValue('user_id') && users.filter(i => i.id === form.getFieldValue('user_id'))[0].lastname}`
        values['date'] = values['date'].format('YYYY-MM-DD')
        values['start'] = `${ values['date']} ${ values['timeslot']}`
        values['end'] = moment(values['start']).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        values['status'] = 'scheduled'
        values['is_repeated'] = values['is_repeated']

        delete values['date']
        delete values['timeslot']

        if (id === 'new') {
           console.log(values)

            axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/appointments`,
                values,
                {headers: {'api': localStorage.getItem('token')}})
                .then(function (response) {
                    console.log(response);
                    // toast.success("Запись успешно создана")
                    // getAppointments()
                    // setShow(false)

                    notification['success']({
                        message: "Запись успешно создана"
                    });

                    history.push({
                        pathname: "/appointments"
                    })
                })
                .catch(function (error) {
                    notification['error']({
                        message: "Ошибка при создании записи.",
                        description: error.response && error.response.data && error.response.data.detail || 'Неизвестная ошибка'
                    });
                })
                .then(function () {

                })
        }


    }

    function handleChangeUser () {
        setUserChildrens(form.getFieldValue('user_id') && users.filter(i => i.id === form.getFieldValue('user_id'))[0].childrens)
        form.setFieldsValue({'child_id': null})
    }

    function onChange() {

        let selected_employee = employees.filter(employee => employee.id === form.getFieldValue('employee_id'))

        let selected_day = selected_employee.length > 0 && selected_employee[0].schedule.filter(date => {
            return date.day === (form.getFieldValue('date') && form.getFieldValue('date').format("YYYY-MM-DD"))
        })

        if (selected_day.length > 0) {
            setAvailableTimeslots(selected_day[0].timeslots)
        }
    }

    function onChangeChild() {
        console.log('onChangeChild')
    }

    useEffect(() => {
        Promise.all([getEmployees(), getUsers()])
            .then(function (results) {
                setLoading(false);

                form.setFieldsValue({'employee_id': location.state && location.state.employee_id})
                form.setFieldsValue({'date': location.state && moment(location.state.date)})
                form.setFieldsValue({'timeslot': location.state && location.state.timeslot})
            });
    }, [])
    
    return <MainLayout>

        <Form
            labelCol={{'span': 4}}
            wrapperCol={{'span': 20}}
            name="normal_login"
            onFinish={handleSubmitForm}
            form={form}
        >

            <Row>
                <Col xs={{ span: 24}} md={{ span: 12}}>

                    <Form.Item name={'user_id'} label="Пользователь" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            // style={{ width: 200 }}
                            placeholder="Выберите пользователя"
                            optionFilterProp="fullname"
                            onChange={handleChangeUser}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            filterOption={(input, option) => {
                                console.log(input, option)
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            }
                        >
                            {
                                users.map((user) => {
                                    return <Option value={`${user.id}`}>{user.fullname}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>

                    <Form.Item name={'child_id'} label="Ребенок" rules={[{ required: false }]}>
                        <Select
                            placeholder="Выберите ребенка"
                            onChange={onChangeChild}
                        >
                            {
                                userChildrens.map((child) => {
                                    return <Option value={`${child.id}`}>{child.firstname}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>


                    <Form.Item name='employee_id' label="Сотрудник" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            // style={{ width: 200 }}
                            placeholder="Выберите сотрудника"
                            optionFilterProp="fullname"
                            onChange={onChange}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            filterOption={(input, option) => {
                                console.log(input, option)
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            }
                        >

                            {
                                employees.map((employee) => {
                                    return <Option value={`${employee.id}`}>{employee.fullname}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>

                    <Form.Item name='date' label="Дата записи" rules={[{ required: true }]}>
                        <DatePicker onChange={onChange} format="YYYY-MM-DD" required={true} message="Укажите дату записи" locale={locale} />
                    </Form.Item>

                    <Form.Item name='timeslot' label="Время записи" rules={[{ required: false }]} >
                        <Select
                            // disabled={!form.getFieldValue('employee_id')}
                            // style={{ width: 200 }}
                            placeholder="Выберите доступный слот"
                            // onChange={onChange}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                        >

                            {
                                availableTimeslots.map((timeslot) => <Option value={`${timeslot}`}>{timeslot}</Option>)
                            }

                        </Select>
                    </Form.Item>

                    <Form.Item name="is_repeated" valuePropName="checked">
                        <Checkbox>Повторять запись</Checkbox>
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            Сохранить
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        
    </MainLayout>
}