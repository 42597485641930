import { Table, Row, Col, Button, Form, Divider, Input, Space} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import MainLayout from "../MainLayout";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";


export function Users() {
    const history = useHistory();
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 1});
    const [loading, setLoading] = useState(false);
    const [sorter, setSorter] = useState({order: 'descend', field: 'created_at'});
    const [users, setUsers] = useState([]);
    const [form] = Form.useForm();
    const [search, setSearch] = useState();
    const columns = [
        {
            title: 'Фамилия',
            dataIndex: 'lastname',
            sorter: true,
        },
        {
            title: 'Имя',
            dataIndex: 'firstname',
            sorter: true,
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            render: phone => `+7${phone}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Создан',
            dataIndex: 'created_at',
            render: created_at => `${new Intl.DateTimeFormat('ru-RU', { dateStyle: 'medium'}).format(new Date(created_at))}`,
            sorter: true,
        },
        {
            title: "Действия",
            key: "action",
            render: (text, record) => <Space size="middle">
                    <a onClick={()=> history.push({pathname: `/users/${record.id}`})}>Редактировать</a>
                </Space>

        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(pagination, filters, sorter)
        setPagination(pagination)
        setSorter(sorter)
    };

    function getUsers() {
        setLoading(true)
        return axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
            {
                headers: {'api': localStorage.getItem('token')},
                params: {
                    'limit': pagination.pageSize,
                    'skip': pagination.pageSize*(pagination.current-1),
                    'sort_field': sorter.field,
                    'sort_order': sorter.order,
                    'search': search
                }},
            )
            .then(function (response) {
                pagination['total'] = response.data.length + pagination.pageSize*pagination.current
                setPagination(pagination)
                setUsers(response.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            }).then(() => setLoading(false))
    }

    function handleSearch(values) {
        console.log(values['field-search'])
        setSearch(values['field-search'])
    }

    const fetch = (params = {}) => {
        setLoading(true);

        setLoading(false);

        // reqwest({
        //     url: 'https://randomuser.me/api',
        //     method: 'get',
        //     type: 'json',
        //     data: getRandomuserParams(params),
        // }).then(data => {
        //     console.log(data);
        //     this.setState({
        //         loading: false,
        //         data: data.results,
        //         pagination: {
        //             ...params.pagination,
        //             total: 200,
        //             // 200 is mock data, you should read it from server
        //             // total: data.totalCount,
        //         },
        //     });
        // });

    };

    useEffect(() => {
        getUsers()
    }, [pagination, search])

    return <MainLayout>
        <div>
            <div>
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={handleSearch}
                >
                    <Row gutter={24}>
                        <Col span={8} key={1}>
                            <Form.Item
                                name={`field-search`}
                                label={`Поиск`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите запрос для поиска',
                                    },
                                ]}
                            >
                                <Input placeholder="Введите запрос" />
                            </Form.Item>
                        </Col>
                        <Col span={8}/>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                Поиск
                            </Button>
                            <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                    setSearch('');
                                }}
                            >
                                Очистить
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <Divider />

            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={users}
                pagination={pagination}
                loading={loading}
                size="small"
                title={() => <div>
                    <Row gutter={[24, 32]}>
                        <Col span={6}>Пользователи</Col>
                        <Col span={6} />
                        <Col span={6} />
                        <Col span={6} align="end">
                            <Button type="primary" icon={<PlusOutlined />} onClick={()=> history.push({pathname: `/users/new`})}>
                                Создать пользователя
                            </Button>
                        </Col>
                    </Row>
                </div>}
                onChange={handleTableChange}
            />
        </div>
    </MainLayout>
}