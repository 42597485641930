import MainLayout from "../MainLayout";
import {Button, Col, Form, Row, Space, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";

export function Employees() {
    const history = useHistory();
    const [pagination, setPagination] = useState({current: 1, pageSize: 30, total: 1});
    const [loading, setLoading] = useState(false);
    const [sorter, setSorter] = useState({order: 'descend', field: 'created_at'});
    const [employees, setEmployees] = useState([]);
    const [form] = Form.useForm();
    const [search, setSearch] = useState();
    const columns = [
        {
            title: 'Фамилия',
            dataIndex: 'lastname',
            sorter: true,
        },
        {
            title: 'Имя',
            dataIndex: 'firstname',
            sorter: true,
        },
        // {
        //     title: 'Телефон',
        //     dataIndex: 'phone',
        //     render: phone => `+7${phone}`,
        // },
        // {
        //     title: 'Создан',
        //     dataIndex: 'created_at',
        //     render: created_at => `${new Intl.DateTimeFormat('ru-RU', { dateStyle: 'medium'}).format(new Date(created_at))}`,
        //     sorter: true,
        // },
        {
            title: "Действия",
            key: "action",
            render: (text, record) => <Space size="middle">
                <a onClick={()=> history.push({pathname: `/employees/${record.id}`})}>Редактировать</a>
            </Space>

        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(pagination, filters, sorter)
        setPagination(pagination)
        setSorter(sorter)
    };


    function getEmployees() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employees`)
            .then(function (response) {
                console.log(response.data)
                setEmployees(response.data)
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
            });
    }

    useEffect(() => {
        getEmployees()
    }, [])

    return <MainLayout>
        <div>


            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={employees}
                pagination={pagination}
                loading={loading}
                size="small"
                title={() => <div>
                    <Row gutter={[24, 32]}>
                        <Col span={6}>Сотрудники</Col>
                        <Col span={6} />
                        <Col span={6} />
                        <Col span={6} align="end">
                            <Button type="primary" icon={<PlusOutlined />} onClick={()=> history.push({pathname: `/employees/new`})}>
                                Создать сотрудника
                            </Button>
                        </Col>
                    </Row>
                </div>}
                onChange={handleTableChange}
            />
        </div>
    </MainLayout>
}