import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {Button, Checkbox, Col, Form, Input, Row, Space, notification, Typography} from 'antd';

const { Title } = Typography;

export function Validate() {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        console.log(location)
        setPhone(location.state.phone)
    }, [location]);

    const [phone, setPhone] = useState();
    const [loading, setLoading] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message
        });
    };


    const onFinish = (values) => {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/token/`, {
            phone: phone,
            code: values.code
        }).then(function (response) {
            setLoading(false);
            localStorage.setItem('token', response.data.access_token);
            history.push({
                pathname: "/"
            })

        }).catch(function (error) {
            setLoading(false);
            // setErrorMessage(error.response.data);
        });

    };

    const onFinishFailed = (errorInfo) => {};


    return <div>

        <Row >
            <Col xs={2} md={9}></Col>
            <Col xs={20} md={6} >
                <div className="login-form">
                    <Title>Подтверждение</Title>
                    <Form
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Код"
                            name="code"
                            rules={[{required: true, message: 'Введите код подтверждения'}]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item>
                            <Button block type="primary" htmlType="submit">
                                Подтвердить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
            <Col xs={2} md={9}></Col>
        </Row>
    </div>
}