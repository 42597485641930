import React, {useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Popconfirm, Row, Table, Typography, Modal, DatePicker, notification} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import axios from "axios";
import 'moment/locale/ru';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/ru_RU';


export default function ChildModal({user_id, child, onChangeChild}) {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    if (child) {
        child.birth_date = child.birth_date ? moment(child.birth_date, "YYYY-MM-DD") : null
        form.setFieldsValue({'child': child})
    }

    const handleAddChild = (values) => {
        setModalVisible(false);
        setLoading(true);

        values.child['user_id'] = user_id
        values.child['birth_date'] = values.child['birth_date'].format("YYYY-MM-DD")

        if (child) {

            axios.put(
                `${process.env.REACT_APP_API_ENDPOINT}/chidlrens/${child.id}`,
                values.child,
                {headers: {'api': localStorage.getItem('token')}})
                .then(function (response) {
                    // setChildrens(childrens.concat(form))

                    notification['success']({
                        message: "Запись успешно обновлена"
                    });
                })
                .catch(function (error) {
                    notification['error']({
                        message: "Ошибка при обновлении записи"
                    });
                })
                .then(function () {
                    setModalVisible(false);
                    setLoading(false);
                    values.child = {}
                    onChangeChild()
                });

        } else {
            axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/chidlrens/`,
                values.child,
                {headers: {'api': localStorage.getItem('token')}})
                .then(function (response) {
                    // setChildrens(childrens.concat(form))

                    notification['success']({
                        message: "Ребенок был успешно добавлен"
                    });
                })
                .catch(function (error) {
                    notification['error']({
                        message: "Ошибка при добавлении ребенка"
                    });
                })
                .then(function () {
                    setModalVisible(false);
                    setLoading(false);
                    values.child = {}
                    onChangeChild()
                });
        }
    };

    const handleCancelAddChild = () => {
        setModalVisible(false);
    };

    return (
        <div>
            <Modal
                title={child ? "Изменение ребенка" : "Добавление ребенка"}
                visible={modalVisible}
                confirmLoading={loading}
                onCancel={handleCancelAddChild}
                footer={null}
            >
                <p>

                    <Form
                        labelCol={{'span': 8}}
                        wrapperCol={{'span': 16}}
                        onFinish={handleAddChild}
                        form={form}
                    >

                        <Form.Item name={['child','firstname']} label="Имя" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['child','birth_date']} label="Дата рождения" rules={[{ required: true }]}>
                            <DatePicker format="YYYY-MM-DD" required={true} message="Укажите дату рождения" locale={locale} />
                        </Form.Item>

                        <div className="ant-modal-footer">

                            <Button onClick={handleCancelAddChild}>
                                Отменить
                            </Button>

                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} >
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                </p>
            </Modal>

            {
                child ? <span onClick={() => setModalVisible(true)}>
                        <a>Редактировать</a>
                    </span> :
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
                        Добавить ребенка
                    </Button>
            }
        </div>
    );
};