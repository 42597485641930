import React, {useEffect, useState} from 'react';
import {Col, Form, Popconfirm, Row, Table, Typography, Modal, notification} from 'antd';
import axios from "axios";
import moment from 'moment'


export default function FutureAppointments({user_id}) {
    const [form] = Form.useForm();
    const [appointments, setAppointments] = useState([]);

    /**
     * Получение предстоящих записей.
     * @returns {Promise<AxiosResponse<any>>}
     */
    function getAppointments() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/appointments`,
            {params:{user_id: user_id, start:"2023-09-25 00:00:00"}, headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                console.log(response.data)
                setAppointments(response.data)
            })
            .catch(function (error) {})
            .then(function () {});
    }


    useEffect(() => {
        getAppointments();


    }, [])

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'start',
            render: (_, record) => {
                return <div>
                    {moment(record.start).format("DD.MM.YYYY HH:mm")}
                    {/* {record.start} */}
                </div>
            }
        },
        {
            title: 'Сотрудник',
            dataIndex: 'start',
            render: (_, record) => {
                return <div>
                    {record.employee.firstname} {record.employee.lastname}
                </div>
            }
        },
        {
            title: 'Дата создания',
            dataIndex: 'start',
            render: (_, record) => {
                return <div>
                    {moment(record.created_at).format('YYYY-MM-DD HH:mm')}
                </div>
            }
        }
    ];

    return (
        <div>
            <Form form={form} component={false}>
                <Table
                    size="small"
                    bordered
                    dataSource={appointments}
                    columns={columns}
                    title={() => <div>
                        <Row gutter={[24, 32]}>
                            <Col span={6}>Предстоящие записи</Col>
                            <Col span={6} />
                            <Col span={6} />
                            <Col span={6} />
                        </Row>
                    </div>}
                />
            </Form>
        </div>
    );
};