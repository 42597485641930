import React, {useEffect, useState} from 'react';
import {Col, Form, Popconfirm, Row, Table, Typography, Modal, notification} from 'antd';
import axios from "axios";
import moment from 'moment'


export default function RepeatedAppointments({user_id}) {
    const [form] = Form.useForm();
    const [appointments, setAppointments] = useState([]);

    /**
     * Получение постоянных записей.
     * @returns {Promise<AxiosResponse<any>>}
     */
    function getAppointments() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/permanent_appointments`,
            {params:{user_id: user_id}, headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                console.log(response.data)
                setAppointments(response.data)
            })
            .catch(function (error) {})
            .then(function () {});
    }

    /**
     * Поиск записей по парамтетрам
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    function findAppointments(params){
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/appointments`, {params:params})
    }


    /**
     * Удаление постоянной записи и всех будущих записей
     * @param appointment
     */
    const handleDisableAppointment = (appointment) => {
        appointment['is_repeated'] = false

        axios.delete(
            `${process.env.REACT_APP_API_ENDPOINT}/api/permanent_appointments/${appointment.id}`,
            {
                headers: {'api': localStorage.getItem('token')},
            })
            .then(function (response) {
                notification['success']({
                    message: "Автозапись была отменена"
                });
                getAppointments();
            })
            .catch(function (error) {
                notification['error']({
                    message: "Ошибка при отмене автозаписи"
                });
            })
            .then(function () {

                findAppointments({
                    user_id: user_id,
                    is_repeated: false,
                    permanent_appointment_id: appointment.id,
                    start: moment().format('YYYY-MM-DD hh:mm')
                }).then((response) => {

                    if (response.data.length > 0) {
                        let appointments_for_cancel = []
                        response.data.forEach(ap => {
                            return axios.delete(
                                `${process.env.REACT_APP_API_ENDPOINT}/appointments/${ap.id}`,
                                {headers: {'api': localStorage.getItem('token')}})
                        })

                        Promise.all(appointments_for_cancel).then(() => {
                            notification['success']({
                                message: "Все будущие будущие записи были отменены"
                            });
                        }).catch(function (error) {
                            notification['error']({
                                message: "Ошибка при отмене будущих связанных записей"
                            });
                        })
                    } else {
                        notification['warning']({
                            message: "Не найдены будущие записи. Проверьте в календаре и при необходимости удалите вручную"
                        });
                    }
                })
            });
    };

    useEffect(() => {
        getAppointments();


    }, [])

    const dayOfWeek = {
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресенье',
    }

    const columns = [
        {
            title: 'День недели',
            dataIndex: 'dow',
            render: (_, record) => {
                return <div>
                    {/*{moment(record.start).format('dddd HH:mm')}*/}
                    {dayOfWeek[record.dow]}
                </div>
            }
        },
        {
            title: 'Время начала',
            dataIndex: 'start',
            render: (_, record) => {
                return <div>
                    {/*{moment(record.start).format('dddd HH:mm')}*/}
                    {record.start}
                </div>
            }
        },
        {
            title: 'Сотрудник',
            dataIndex: 'start',
            render: (_, record) => {
                return <div>
                    {record.employee.firstname} {record.employee.lastname}
                </div>
            }
        },
        {
            title: 'Дата создания',
            dataIndex: 'start',
            render: (_, record) => {
                return <div>
                    {moment(record.created_at).format('YYYY-MM-DD HH:mm')}
                </div>
            }
        },
        {
            title: 'Действия',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Popconfirm title="Подтверждаете отмену автозаписи? Это приведет к отмене всех будущих записей." onConfirm={() => handleDisableAppointment(record)}>
                    <a>Отключить автозапись</a>
                </Popconfirm>
            },
        },
    ];

    return (
        <div>
            <Form form={form} component={false}>
                <Table
                    size="small"
                    bordered
                    dataSource={appointments}
                    columns={columns}
                    title={() => <div>
                        <Row gutter={[24, 32]}>
                            <Col span={6}>Постоянные записи</Col>
                            <Col span={6} />
                            <Col span={6} />
                            <Col span={6} />
                        </Row>
                    </div>}
                />
            </Form>
        </div>
    );
};