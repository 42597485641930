import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import {Button, Checkbox, Col, Form, Input, Row, Space, notification, Typography} from 'antd';


const { Title } = Typography;

export function Login() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message
        });
    };


    const onFinish = (values) => {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login/`, {phone: values.phone})
            .then(function (response) {
                setLoading(false);
                history.push({
                    pathname: "/validate",
                    state: {phone: values.phone}
                })
                openNotificationWithIcon('success', 'Код был выслан на указанный номер телефона')
            })
            .catch(function (error) {
                setLoading(false);
                openNotificationWithIcon('error', error.response.data.detail)
            });
    };

    const onFinishFailed = (errorInfo) => {};

    return <div>

        <Row >
            <Col xs={2} md={9}></Col>
            <Col xs={20} md={6} >
                <div className="login-form">
                    <Title>Вход</Title>
                    <Form
                        name="basic"
                        // labelCol={{span: 4}}
                        // wrapperCol={{span: 20}}
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Телефон"
                            name="phone"
                            rules={[{required: true, message: 'Введите ваш телефон'}]}
                        >
                            <Input addonBefore="+7"/>
                        </Form.Item>

                        {/*<Form.Item*/}
                        {/*    label="Password"*/}
                        {/*    name="password"*/}
                        {/*    rules={[{required: true, message: 'Please input your password!'}]}*/}
                        {/*>*/}
                        {/*    <Input.Password/>*/}
                        {/*</Form.Item>*/}

                        {/*<Form.Item name="remember" valuePropName="checked" wrapperCol={{offset: 8, span: 16}}>*/}
                        {/*    <Checkbox>Remember me</Checkbox>*/}
                        {/*</Form.Item>*/}

                        <Form.Item>
                            <Button block type="primary" htmlType="submit">
                                Получить код
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
            <Col xs={2} md={9}></Col>
        </Row>


    </div>
}