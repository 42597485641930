import MainLayout from "../MainLayout";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom"
import {Form, Input, Button, Row, Col, Divider, notification} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from "axios";
import ChildTable from './ChildTable'
import RepeatedAppointments from "./RepeatedAppointments";
import FutureAppointments from "./FutureAppointments";

const { TextArea } = Input;

export function User() {
    let { id } = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [user, setUser] = useState();

    function getUser() {
        setLoading(true);

        return axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/api/users/${id}`,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                console.log(response.data)
                setUser(response.data)
                form.setFieldsValue({'user': response.data})
            })
            .catch(function (error) {
                console.log(error);
                notification['error']({
                    message: "Ошибка при получении деталей пользователя."
                });
            })
            .then(function () {
                setLoading(false);
            });
    }

    function handleSubmitForm(values) {
        let form = values.user;
        form['user_type'] = 'new';

        console.log('Received values of form: ', values);

        if (id === 'new') {
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/users/`, form)
                .then(function (response) {
                    notification['success']({
                        message: "Пользователь успешно создан"
                    });

                    history.push({
                        pathname: `/users/${response.data.id}`
                    })

                    window.location.reload();

                })
                .catch(function (error) {
                    // setResponse({message: error.response.data.detail, type: 'danger'})
                    notification['error']({
                        message: error.response.data.detail
                    });
                })
                .then(() => {
                    setLoading(false);
                })
        } else {
            axios.put(`${process.env.REACT_APP_API_ENDPOINT}/users/${id}`, form, {headers: {'api': localStorage.getItem('token')}})
                .then(function (response) {
                    notification['success']({
                        message: "Пользователь успешно обновлен"
                    });
                })
                .catch(function (error) {
                    notification['error']({
                        message: error.response.data.detail
                    });
                })
                .then(function () {
                    setLoading(false)
                });
        }

    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    const handleChangeChild = () => {
        getUser();
    }

    useEffect(() => {

        if (id !== 'new') {
            getUser();
        }

    }, [])

    return <MainLayout>
        <Form
            labelCol={{'span': 4}}
            wrapperCol={{'span': 20}}
            name="normal_login"
            onFinish={handleSubmitForm}
            form={form}
        >

            <Row>
                <Col xs={{ span: 24}} md={{ span: 12}}>
                    <Form.Item name={['user', 'firstname']} label="Имя" rules={[{ required: true }]}>
                        <Input value={user && user.firstname} />
                    </Form.Item>
                    <Form.Item name={['user', 'lastname']} label="Фамилия" rules={[{ required: false }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24}} md={{ span: 12}}>

                    <Form.Item name={['user', 'phone']} label="Телефон" rules={[{ required: true }]}>
                        <Input addonBefore="+7"/>
                    </Form.Item>
                    <Form.Item name={['user', 'comment']} label="Описание" >
                        <TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <Divider/>

        {
            user && id !== 'new' ? <ChildTable childrens_pros={user && user.childrens} user_id={id} onChangeChild={handleChangeChild}/> : ""
        }

        <Divider/>
        {
            user && id !== 'new' ? <RepeatedAppointments user_id={id} /> : ""
        }

        <Divider/>
        {
            user && id !== 'new' ? <FutureAppointments user_id={id} /> : ""
        }


    </MainLayout>
}