import React from 'react';
import Appointments from "./Appointments";
import Employees from "./Employees";
import Users from "./Users";
import Register from "./Register";
import Login from "./Login";
import Validate from "./Validate";
import User from "./User";
import Employee from "./Employee";
import Appointment from "./Appointment";
import axios from "axios";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, useHistory
} from "react-router-dom";

import './App.less';
import {notification} from "antd";
import moment from "moment";

moment.locale('ru', {
    week : {
        dow : 1, // Monday is the first day of the week.
    }
});


const App = () => {
    const history = useHistory();

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response.status) {
            notification['warning']({
                message: "Вы не авторизованы"
            });


            setTimeout(() => {window.location = '/login'}, 2000)


        } else if (403 === error.response.status) {
            notification['warning']({
                message: "У вас нет прав на данную операцию. Обратитесь к администратору."
            });
        } else {
            return Promise.reject(error);
        }
    });

    return <div className="App">
        <Router>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/register">
                    <Register/>
                </Route>
                <Route path="/validate">
                    <Validate/>
                </Route>
                <Route path="/appointments/:id">
                    <Appointment />
                </Route>
                <Route path="/appointments">
                    <Appointments />
                </Route>
                <Route path="/employees/:id">
                    <Employee />
                </Route>
                <Route path="/employees">
                    <Employees />
                </Route>
                <Route path="/users/:id">
                    <User />
                </Route>
                <Route path="/users">
                    <Users />
                </Route>
                <Route path="/">
                    <Redirect to="/appointments" />
                </Route>
            </Switch>
        </Router>
    </div>
};

export default App;