import MainLayout from "../MainLayout";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom"
import {Form, Input, Button, Row, Col, Divider, notification, Upload} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import {EmployeeSchedule} from "./EmployeeSchedule";
import './Employee.less'

const { TextArea } = Input;

export function Employee() {
    let { id } = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [photoUrl, setPhotoUrl] = useState();
    const [employee, setEmployee] = useState();

    function getEmployee() {
        setLoading(true);

        return axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/employees/${id}`,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                console.log(response.data)
                setEmployee(response.data)
                form.setFieldsValue({'employee': response.data})

                setPhotoUrl(response.data.photo_url)
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                setLoading(false);
            });
    }

    function handleSubmitForm(values) {
        setLoading(true);

        let form = values.employee;
        form['photo_url'] = photoUrl
        console.log('Received values of form: ', values);

        form['timeslot_duration'] = 30;

        if (id === 'new') {
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/employees`, form)
                .then(function (response) {
                    history.push({
                        pathname: `/employees/${response.data.id}`
                    })
                    notification['success']({
                        message: "Сотрудник успешно создан"
                    });
                })
                .catch(function (error) {

                    notification['error']({
                        message: "Ошибка при создании сотрудника"
                    });
                })
                .then(() => {
                    setLoading(false);
                })
        } else {
            axios.put(`${process.env.REACT_APP_API_ENDPOINT}/employees/${id}`, form, {headers: {'api': localStorage.getItem('token')}})
                .then(function (response) {
                    notification['success']({
                        message: "Сотрудник успешно обновлен"
                    });
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    notification['error']({
                        message: "Ошибка при обновлении сотрудника"
                    });
                })
                .then(function () {
                    setLoading(false)
                });
        }

    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    const handleChangeChild = () => {
        getEmployee();
    }

    useEffect(() => {

        if (id !== 'new') {
            getEmployee();
        }

    }, [])


    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleChangePhoto = info => {
        console.log(info)
        if (info.file.status === 'uploading') {
            // this.setState({ loading: true });
            setLoadingPhoto(true)
            return;
        }
        if (info.file.status === 'done') {
            setLoadingPhoto(false)
            setPhotoUrl(info.file.response.filename)
        }
    };

    return <MainLayout>
        <Form
            labelCol={{'span': 4}}
            wrapperCol={{'span': 20}}
            name="normal_login"
            onFinish={handleSubmitForm}
            form={form}
        >

            <Row>
                <Col xs={{ span: 24}} md={{ span: 12}}>
                    <Form.Item name={['employee', 'firstname']} label="Имя" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'lastname']} label="Фамилия" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={['employee', 'description']} label="Описание" >
                        <TextArea />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            Сохранить
                        </Button>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24}} md={{ span: 12}}>
                    <Form.Item
                        name="upload"
                        label="Фото"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload
                            name="file"
                            action={`${process.env.REACT_APP_API_ENDPOINT}/files`}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            onChange={handleChangePhoto}
                        >
                            {photoUrl ? <img src={`${process.env.REACT_APP_API_ENDPOINT}/files/${photoUrl}`} alt="avatar" style={{ width: '100%' }} /> : <div>
                                {loadingPhoto ? <LoadingOutlined /> : <PlusOutlined />}
                                <div style={{ marginTop: 8 }}>Загрузка</div>
                            </div>}

                        </Upload>
                    </Form.Item>

                </Col>
            </Row>
        </Form>

        <Divider/>




        {
            employee && id !== 'new' ? <EmployeeSchedule employee_id={id}/> : ""
        }

    </MainLayout>
}