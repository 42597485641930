import React, {useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Popconfirm, Row, Table, Typography, Modal, notification, TimePicker} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import axios from "axios";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import DatePicker from "react-multi-date-picker"
import './EmployeeScheduleModal.less'

export default function EmployeeScheduleModal({employee_id, onChangeSchedule}) {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);

    const gregorian_en_lowercase = {
        name: "gregorian_en_lowercase",
        months: [
            ["январь", "янв"],
            ["февраль", "фев"],
            ["март", "мар"],
            ["апрель", "апр"],
            ["май", "май"],
            ["июнь", "июн"],
            ["июль", "июл"],
            ["август", "авг"],
            ["сентябрь", "сен"],
            ["октябрь", "окт"],
            ["ноябрь", "ноя"],
            ["декабрь", "дек"],
        ],
        weekDays: [
            ["суббота", "сб"],
            ["воскресенье", "вс"],
            ["понедельник", "пн"],
            ["вторник", "вт"],
            ["среда", "ср"],
            ["четверг", "чт"],
            ["пятница", "пт"],
        ],
        digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
        meridiems: [
            ["AM", "am"],
            ["PM", "pm"],
        ],
    };

    const handleFinish = (values) => {
        setModalVisible(false);
        setLoading(true);

        // values.child['user_id'] = user_id
        // values.child['birth_date'] = values.child['birth_date'].format("YYYY-MM-DD")

        values.schedule['end'] = values.schedule['end'].format("HH:mm:ss");
        values.schedule['start'] = values.schedule['start'].format("HH:mm:ss")
        values.schedule['employee_id'] = employee_id
        values.schedule['true'] = true

        selectedDates.map(selectedDate => {
            console.log(selectedDate.format("YYYY-MM-DD"), values.schedule)

            values.schedule['day'] = selectedDate.format("YYYY-MM-DD")

            axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/employees/${employee_id}/schedule`,
                values.schedule,
                {headers: {'api': localStorage.getItem('token')}}
            )
                .then(function (response) {
                    onChangeSchedule()
                })
                .catch(function (error) {

                })
                .then(function () {

                });
        })

        setLoading(false);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    function handleChangeDates(value){
        setSelectedDates(value)
    }

    return (
        <div>
            <Modal
                title="Добавление расписания"
                visible={modalVisible}
                confirmLoading={loading}
                onCancel={handleCancel}
                footer={null}
            >
                <p>

                    <Form
                        labelCol={{'span': 8}}
                        wrapperCol={{'span': 16}}
                        onFinish={handleFinish}
                        form={form}
                    >
                        <div className="ant-row ant-form-item">
                            <div className="ant-col ant-col-8 ant-form-item-label">
                                <label className="ant-form-item-required" title="Даты">Даты</label>
                            </div>
                            <div className="ant-col ant-col-16 ant-form-item-control">
                                <div className="ant-form-item-control-input-content">
                                    <DatePicker
                                        multiple
                                        locale={gregorian_en_lowercase}
                                        weekStartDayIndex={1}
                                        value={selectedDates}
                                        format="DD-MM-YYYY"
                                        numberOfMonths={2}
                                        onChange={handleChangeDates}
                                        inputClass={"ant-input"}
                                        containerClassName={""}
                                        containerStyle={{
                                            width: "100%"
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <Form.Item name={['schedule','start']} label="Время начала" rules={[{ required: true }]}>
                            <TimePicker minuteStep={30} format="HH:mm" disabledHours={() => [0,1,2,3,4,5,6,7,8,22,23]} />
                        </Form.Item>

                        <Form.Item name={['schedule','end']} label="Время окончания" rules={[{ required: false }]}>
                            <TimePicker minuteStep={30} format="HH:mm" disabledHours={() => [0,1,2,3,4,5,6,7,8,22,23]} />
                        </Form.Item>

                        <div className="ant-modal-footer">
                            <Button onClick={handleCancel}>
                                Отменить
                            </Button>

                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} >
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                </p>
            </Modal>

            <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
                Добавить расписание
            </Button>
        </div>
    );
};